html {
  font-size: calc(.5rem + 1vw - 1px);
  background: #000;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.secondary-color {
  color: #c6c6c6;
}
.pin-pad {
  text-align: center;
}

.pin-code {
  display: flex;
  height: 2.6rem;
}

.pin-code .pin-digit {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid gray;
  margin: 0 0.2rem;
  border-radius: 6px;
  color: #E6E6E6;
}

.key-pad {
  overflow: hidden;
  border-radius: 6px;
  height: 11rem;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0.2rem 0 0.2rem;
}

.btn-keypad {
  flex: 1 1 calc(100% / 3 - 3px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3E454D;
  margin: 0.5px;
  cursor: pointer;
  font-weight: 600;
}

.btn-keypad.active {
  background-color: #E6E6E6;
}

.btn-keypad > svg {
  width: 1.2rem;
  height: 1.2rem;
}

.btn-keypad>* {
  color: #E6E6E6;
  fill: #E6E6E6 !important;
}

.btn-keypad.active>* {
  color: #4C5059;
  fill: #4C5059 !important;
}
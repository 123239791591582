.connect-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 1.5rem;
}

.top, .bottom {
  display: flex;
  margin: 1.2rem 0;
  justify-content: space-between;
  width: 100%;
}

.top {
  align-items: center;
}

.bottom {
  align-items: flex-end;
}

.brand {
  display: flex;
  align-items: center;
}

.brand .logo {
  width: 3rem;
  height: 3rem;
}


.connection-url {
  display: block;
  height: 2.2rem;
  min-width: 12.25rem;
  border: none;
  font-size: inherit;
  border-radius: 6px;
  background-color: rgb(var(--sand-focus-bg-color-rgb, 230, 230, 230));
  text-align: center;
}

.btn-connect {
  height: 2.2rem;
  font-weight: 600;
  margin: 1rem 0.2rem 0 0.2rem;
  background-color: #3E454D;
  color: #E6E6E6;
  font-size: inherit;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  min-width: 12.25rem;
}

.btn-connect:disabled,
.btn-connect[disabled]{
  opacity: 0.28;
  cursor: default;
}

.btn-connect.active {
  background-color: #E6E6E6;
  color: #4C5059;
}
.content-container {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.7rem;
}

.logo {
  height: 10rem;
  width: 10rem;
}